<template>
  <div id="error">
    <div class="main-page-wrapper p0">
      <!-- ===================================================
				Loading Transition
			==================================================== -->
      <section>
        <div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
      </section>


      <div class="error-page d-lg-flex align-items-center">
        <div class="img-holder order-lg-last">
          <img src="../assets/v2/images/media/404.svg" alt="" class="w-100 illustration">
          <img src="../assets/v2/images/media/404-q.svg" alt="" class="shapes qus">
        </div>
        <div class="text-wrapper order-lg-first">
          <div class="logo"><router-link :to="{ name: 'home' }"><img data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt=""></router-link></div>
          <h1 class="font-slab">Uh-oh! You've ventured into the unknown.</h1>
          <p class="font-rubik"></p>
          <router-link :to="{ name: 'home' }"
            class="back-home font-rubik d-flex align-items-center justify-content-center">
            <span>Back to Ingomu</span>
            <img src="../assets/v2/images/icon/53.svg" alt="">
          </router-link>
        </div> <!-- /.text-wrapper -->
      </div> <!-- /.error-page -->
    </div>
  </div>
</template>

<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";

export default {
  name: "error",
  components: {
    SiteHeader,
    SiteFooter,
  },
  mounted() {
    postscribe(
      "#error",
      `<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
    );
    postscribe(
      "#error",
      `<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
    );
  },
};
</script>

<style scoped>
.error-page__code {
  color: #3b5998;
}

.w-80 {
  width: 80% !important;
}
</style>