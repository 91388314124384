<template>
	<div id="bhl">
		<div class="main-page-wrapper font-gordita">
			<!-- ===================================================
					Loading Transition
				==================================================== -->
			<section>
				<div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>


			<!-- 
				=============================================
					Theme Main Menu
				============================================== 
				-->
			<SiteHeader></SiteHeader>

			<!--
				=====================================================
					Fancy Short Banner Ten
				=====================================================
				-->
			<div class="fancy-short-banner-ten md-mt-20 pb-0" id="freetrial">
				<div class="container">
					<div class="row">
						<div class="col-xl-7 col-lg-8 m-auto" data-aos="fade-up" data-aos-duration="1200">
							<div class="text-center">
								<img class="mx-auto d-block mb-40" data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt="">
								<h2 class="font-gordita fs-50 fw-600">Start your 7-day free trial today!</h2>
								<p class="fs-20">Transform your life with unlimited access to experienced life,
									wellness, work, and soul coaches. Enjoy live virtual group coaching and watch
									recorded sessions at your convenience. Start with a 7-day free trial, then continue
									for $29/month, with the freedom to cancel anytime. Start your transformative journey
									today!</p>
							</div>
						</div>
					</div>
					<!-- <div class="text-center pb-40 md-pb-10" data-aos="fade-up" data-aos-duration="1200"
							data-aos-delay="150">
							<a href="#" data-toggle="modal" data-target="#contactModal" class="theme-btn-eleven">LET'S TALK</a>
						</div> -->

					<div class="d-flex justify-content-center pb-40 md-pb-10" data-aos="fade-up"
						data-aos-duration="1200" data-aos-delay="150">
						<div class="col-lg-3 col-sm-5">
							<div class="dropdown download-btn style-two w100">
								<button class="dropdown-toggle w100" type="button" id="dropdownMenuButton"
									data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Download
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<a class="dropdown-item d-flex align-items-center" target="_blank"
										href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu">
										<img src="../../../assets/v2/images/icon/Android_robot.svg" class="h22" alt="">
										<span>Android</span>
									</a>
									<a class="dropdown-item d-flex align-items-center" target="_blank"
										href="https://apps.apple.com/us/app/ingomu/id1621410470">
										<img src="../../../assets/v2/images/icon/apple-icon.svg" class="h22" alt="">
										<span>IOS</span>
									</a>
								</div>
							</div>
						</div>


					</div>


				</div> <!-- /.container -->
				<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/210.svg" alt=""
					class="shapes shape-one">
				<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/211.svg" alt=""
					class="shapes shape-two">
			</div> <!-- /.fancy-short-banner-ten -->

			<!--
				=====================================================
					Fancy Short Banner Four
				=====================================================
				-->
			<div class="fancy-short-banner-four mt-100 mb-100" id="newsletter">
				<div class="container">
					<div class="bg-wrapper">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<div class="title">
									<h6 class="text-white">ACTIONABLE TIPS FOR A BETTER LIFE</h6>
									<h2>Weekly coaching tips in your inbox</h2>
								</div> <!-- /.title-style-one -->
							</div>
							<div class="col-lg-6">
								<div class="form-wrapper">
									<form type="email" v-on:submit.prevent="submit('newsletter')" data-vv-scope="newsletter">
										<input class="form-control border-0 required email" v-model="newsletterForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('newsletter.email') }"
                                    placeholder="Your Email Address" type="email" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="A valid email is required." />
										<button type="submit">Subscribe</button>
									</form>
									<p class="font-rubik text-white">
										By subscribing to our newsletter, you allow us to contact you at the email
										address
										provided. You may unsubscribe at any time. Your information will never be shared
										or
										sold.
									</p>
								</div> <!-- /.form-wrapper -->
							</div>
						</div>
					</div> <!-- /.bg-wrapper -->
				</div> <!-- /.container -->
			</div> <!-- /.fancy-short-banner-four -->

			<!--
				=====================================================
					Faq Classic
				=====================================================
				-->
			<a href="#faq">&nbsp;</a>
			<div class="faq-classic with-bg pt-0 pb-0 bg-white md-mt-100 mt-100 sm-mt-0" id="faq" ref="faq">
				<div class="container">
					<div class="title-style-four text-center mb-100 md-mb-70">
						<div class="row">
							<div class="col-lg-7 m-auto">
								<h6 class="text-dark">FAQ’s</h6>
								<h2 class="fs-50 fw-600">
									<span>Questions & Answers</span>
								</h2>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordion">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordiontwo">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral1" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> <!-- /.faq-classic -->

			<!--
				=====================================================
					Footer Style Seven
				=====================================================
				-->
			<SiteFooter></SiteFooter>

			<!-- Modal Contact Form One -->
			<div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
				aria-labelledby="contactModalTitle" aria-hidden="true">
				<ContactUs @clicked="closeModal"></ContactUs>
			</div>

			<!-- Scroll Top Button -->
			<button class="scroll-top">
				<i class="fa fa-angle-up" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</template>

<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/BhlHeader";
import SiteFooter from "@/components/SiteFooter";
import ContactUs from "@/components/ContactUs";
import { mapActions, mapGetters } from "vuex";

const TIMEOUT = 1000;

export default {
	name: "bhl",
	metaInfo() {
		return {
			title: "Ingomu and Best Holistic Life: Your Ultimate Wellness Partners",
			meta: [
				{ name: 'description', content: 'First-class coaches to help you with your life, work, soul, and wellness goals, accessible anytime, anywhere.' },
				{ property: 'og:title', content: "Personal Coaching Made Affordable" },
				{ property: 'og:site_name', content: 'Ingomu' },
				{ property: 'og:type', content: 'website' },
				{ name: 'robots', content: 'index,follow' }
			]
		}
	},
	components: {
		SiteHeader,
		SiteFooter,
		ContactUs
	},
	data() {
		return {
			newsletterForm: {
                email: ""
            },
			faqCategories: [
				{
					id: "v-pills-general-tab",
					href: "#v-pills-general",
					controls: "v-pills-general",
					title: "General",
					class: "nav-link active",
				},
			],
			faqGeneral: [
				{
					question: "Who will be my coaches?",
					answer:
						"You have unlimited access to all life, work, wellness, and soul coaches on the app. Coaches are vetted, and where possible certified in their area of coaching.",
					id: "headingOne",
					target: "#collapseOne",
					controls: "collapseOne",
					show: "show",
				},
				{
					question: "How do I work with the coaches?",
					answer:
						"Via the Ingomu app you have access to all coaches. You can join scheduled live virtual group sessions, and in case you missed a session, or want to see it again, you can always watch any session on demand. You can also send one-on-one messages to your coaches between sessions.",
					id: "headingTwo",
					target: "#collapseTwo",
					controls: "collapseTwo",
					show: "",
				},
				{
					question: "How can I access Ingomu?",
					answer:
						'Ingomu is accessible via the Ingomu app for iOS and Android. You can download it from <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">Apple App Store</a> and <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu" target="_blank">Google Play</a>.',
					id: "headingThree",
					target: "#collapseThree",
					controls: "collapseThree",
					show: "",
				},
				{
					question: "What is included in my subscription?",
					answer:
						"Your subscription gives you access to all coaches and their live group coaching sessions, as well as hundreds of recorded sessions, accessible on-demand and on your time. Your subscription also gives you the opportunity to engage with coaches via discussions between sessions, and one-on-one messaging. Get the help you need, when you need it.",
					id: "headingFour",
					target: "#collapseFour",
					controls: "collapseFour",
					show: "",
				}
			],
			faqGeneral1: [
				{
					question: "How are subscriptions priced?",
					answer:
						"Subscriptions are $29 US per month after a 7-day full access free trial.  Once subscribed, upgrade and save with a semi-annual subscription at $165. Save even more with an annual subscription at $290 US. Imagine unlimited access to all our coaches for the cost of one coaching session. Contact us for volume pricing for your company, organization, institution or school.",
					id: "headingFive",
					target: "#collapseFive",
					controls: "collapseFive",
					show: "",
				},
				{
					question: "Can I try the app before signing up?",
					answer:
						'We invite you to sign up for a 7-day full access free trial. You can download the app for <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu" target="_blank">Android</a>.',
					id: "headingSix",
					target: "#collapseSix",
					controls: "collapseSix",
					show: "",
				},
				{
					question: "How do I cancel my subscription?",
					answer:
						"You can cancel your subscription at any time by going to the App or Play store on your device. Follow instructions to cancel your plan. Make sure you cancel your plan no less than 24 hours prior to your renewal date to ensure you do not get billed.",
					id: "headingSeven",
					target: "#collapseSeven",
					controls: "collapseSeven",
					show: "",
				},
				{
					question: "How do I delete my account and data?",
					answer:
						"You can delete your account and remove your data from the Account menu in the Ingomu app. Click the Delete Account option in the My Account section.",
					id: "headingEight",
					target: "#collapseEight",
					controls: "collapseEight",
					show: "",
				}
			],
		}
	},
	mounted() {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
		}
		this.$store.dispatch("getPricing");
		postscribe(
			"#bhl",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
		);
		postscribe(
			"#bhl",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
		);
	},
	methods: {
		...mapActions(["newsletterConsumer"]),
		submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .newsletterConsumer({
                            email: _this.newsletterForm.email
                        })
                        .then(() => {
                            _this.newsletterForm.email = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        },
		closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your request. Keep an eye on your inbox; we'll be in touch shortly to schedule our call. Thank you for choosing Ingomu.",
					type: "success",
				});
			}
		},
		scrollTo(hashtag) {
			// setTimeout(() => { document.getElementById(hashtag.slice(1)).scrollIntoView() }, TIMEOUT)
			setTimeout(() => {
				const element = document.getElementById(hashtag.slice(1)); // Your target element
				const headerOffset = 150;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}, TIMEOUT)
		},
	},
}
</script>
<style scoped>
.w997 {
	width: 997px;
}

.app-screen-preview-one {
	margin-top: 70px !important;
}

.block-style-six:nth-child(even):before {
	background: none;
}

.mb-50 {
	margin-bottom: 50px;
}

.mt-150 {
	margin-top: 150px;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-45 {
	font-size: 45px;
}

.pb-0 {
	padding-bottom: 0;
}

.bg-lblue {
	background: #F7FAFF;
}

.bg-white {
	background: #fff;
}

.b-neg50 {
	bottom: -50px
}

.client-feedback-slider-two .bg-wrapper:before {
	bottom: 5px;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.pt-40 {
	padding-top: 40px;
}

.fs-30 {
	font-size: 30px;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pt-100 {
	padding-top: 100px !important
}

.pb-150 {
	padding-bottom: 150px
}

.p40 {
	padding: 40px !important;
}

.counter-box-one p {
	font-size: 15px !important;
}

.pt-150 {
	padding-top: 150px !important
}

.fs30 {
	font-size: 30px !important;
}

.counter-box-one.color-three,
.counter-info-classic .bubble-two,
.counter-info-classic .bubble-four,
.counter-info-classic .bubble-five,
.counter-info-classic .bubble-seven {
	background: #3b5998;
}

.counter-box-one.color-two,
.counter-info-classic .bubble-one,
.counter-info-classic .bubble-three,
.counter-info-classic .bubble-six {
	background: #46c346;
}

.mb40 {
	margin-bottom: 40px !important;
}

.title-style-four span img {
	bottom: -50px !important
}

.fw600 {
	font-weight: 600 !important;
}

.h22 {
	height: 22px;
}

.download-btn button {
	width: 190px;
	line-height: 60px;
	border-radius: 9px;
	font-weight: 500;
	font-size: 18px;
	color: #000;
	background: #FFEAA0;
	padding-left: 12px;
}

.download-btn.style-two button {
	background: #fff;
	border: 2px solid #000;
	line-height: 56px;
}

.download-btn button:after {
	content: url(../../../assets/v2/images/icon/89.svg);
	border: none;
	margin: 0 0 0 11px;
	vertical-align: 0;
	transition: all 0.2s ease-in-out;
}

.download-btn.show button:after {
	transform: rotate(180deg);
}

.download-btn .dropdown-menu {
	width: 100%;
	background: #FFF5D1;
	border-radius: 0px 0px 10px 10px;
	border: none;
	margin: -5px 0 0 0;
}

.download-btn.style-two .dropdown-menu {
	background: #fff;
	border: 2px solid #000;
}

.download-btn .dropdown-menu a {
	font-weight: 500;
	font-size: 15px;
	color: #000;
	padding: 8px 15px;
	transition: all 0.3s ease-in-out;
}

.download-btn .dropdown-menu a:hover,
.download-btn .dropdown-menu a:focus {
	background: rgba(255, 255, 255, 0.5);
}

.download-btn .dropdown-menu a span {
	padding-left: 12px;
}

.w100 {
	width: 100% !important;
}

.fs58 {
	font-size: 58px;
}

.fs12 {
	font-size: 12px !important;
}

.clientSliderSix .feedback-wrapper:before,
.clients-feedback-classic .feedback-wrapper:before {
	content: none !important;
}
</style>
<style>
@media (max-width: 768px) {
	.body-padding {
		padding: 0 20px !important;
	}
}

@media (max-width: 1370px) {
	.clients-feedback-classic {
		padding-left: 40px;
	}
}

.hero-banner-fourteen {
	padding-top: 100px !important;
}

.hero-banner-fourteen .hero-heading {
	font-size: 50px;
}

.screen-holder {
	display: flex;
	justify-content: center;
	align-items: center;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	width: 500px;
	height: 500px;
	border-radius: 50%;
	overflow: hidden;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	font-weight: bold;

	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#3b5998, #3b5998), linear-gradient(#46c346, #46c346);
		animation: rotate 4s linear infinite;
	}

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 50%;
	}
}

@media (max-width: 768px) {
	.rainbow {
		width: 400px;
		height: 400px;
	}

	.display-none {
		display: none;
	}
}


.hero-banner-fourteen .screen-holder .vr-image {
	right: auto;
}

.hero-banner-fourteen .screen-holder {
	top: 5%;
}

@media (max-width: 1370px) {
	.hero-banner-fourteen .screen-holder {
		margin-top: 0;
	}
}

.hero-banner-fourteen .screen-holder .vr-image {
	bottom: auto;
}

.fs-35 {
	font-size: 35px !important;
}

.hero-banner-fourteen .hero-heading {
	text-transform: none;
}

.fancy-short-banner-four .bg-wrapper {
	background: #3b5998;
	border-radius: 25px;
}

.mt-100 {
	margin-top: 100px !important
}

.tl-50 {
	transform: translateX(40%);
}

.fs-50 {
	font-size: 50px !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.client-feedback-slider-seven .bg-image {
	max-height: 100%;
	max-width: 100%;
	padding: 0 20px;
}

.hero-banner-fourteen .price {
	font-size: 60px;
}

@media (max-width: 1199px) {
	.hero-banner-fourteen .price {
		font-size: 45px;
	}
}

.fancy-short-banner-four .form-wrapper form button:hover {
	background: #46c346 !important;
	color: #fff;
}

@media (max-width: 767px) {
	.sm-mt-0 {
		margin-top: 0 !important;
	}

	.sm-pt-0 {
		padding-top: 0 !important
	}
}

#actionemail::placeholder {
	color: white;
}
</style>